import React from "react";
import "./intro.css";
import backgroundImage from "../../assets/website_jake.jpg";
import hardResume from ".././../assets/Joseph_s_Resume_Hardware.pdf";



const Intro = () => {
    return (
        <section id="intro">
            <div className="introContent">
                <span className="welcome">
                    Welcome,
                </span>
                <span className="introText">
                    I'm <span className="introName">Jake Malegni</span>,
                    <br />
                    Computer Engineering
                    <br />git
                    Student
                </span>
                <p className="introParagraph">
                    Currently studying at the University of Florida.
                    <br/>
                    Check out my <a href={hardResume} download="Joseph_s_Resume_Hardware.pdf">resume</a>

                </p>
            </div>
            <img src={backgroundImage} alt="Joseph at Osaka Castle" className="bg" />
        </section>
    );
}

export default Intro;
